'use client'

import PrimaryButton from '@components/Home/PrimaryButton/Button'
import { HomeComponentType } from '@components/HomePage/utils/HomeComponentType'
import Image from 'next/image'
import { When } from 'react-if'
import { ProductCardButton } from '@components/Home/ProductCardButton/ProductCardButton'
import { useState } from 'react'
import dynamic from 'next/dynamic'
import PropertyControlledComponent from '@components/PropertyControlledComponent/PropertyControlledComponent'
import styles from './HomeLandingWidget.module.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import { AutCarouselComponent, FEATURED_CARD_OFFSET, HOME_PAGE_ROUTE, PRODUCTS_LENGTH, VARIANT } from './constants'

const getDynamicComponents = {
  [AutCarouselComponent]: dynamic(() => import('@components/Home/AutoScrollCarousel/AutoScrollCarousel')),
}
interface Item {
  featured?: boolean
  iconImage: string
  id: number
  name: string
  url: string
  image: string
  featuredTitle: string
  featuredSubtitle: string
  shortName: string
  productDisclaimer?: string
}
interface HomeLandingWidgetProps {
  heading: string
  productHeading: string
  product: Item[]
  ctaText: string
  ctaTextMobile: string
  heroImage: string
}

const CURRENT_VARIANT = VARIANT.WITH_PRODUCT_CARDS

const HomeLandingWidget = ({
  heading,
  productHeading,
  product,
  ctaText,
  ctaTextMobile,
  heroImage,
}: HomeLandingWidgetProps) => {
  const Carousel = getDynamicComponents[AutCarouselComponent]
  const [currentIndex, setCurrentIndex] = useState(0)
  return (
    <div
      id={HomeComponentType.LandingWidget}
      className={`${styles.widgetContainer} ${
        CURRENT_VARIANT === VARIANT.WITHOUT_PRODUCT_CARDS
          ? styles.variantA
          : CURRENT_VARIANT === VARIANT.WITH_PRODUCT_CARDS
            ? styles.variantB
            : ''
      }`}>
      <div className={styles.dataContainer}>
        <div>
          <h1 dangerouslySetInnerHTML={{ __html: heading }} />

          <When condition={CURRENT_VARIANT === VARIANT.WITH_PRODUCT_CARDS}>
            <div className={styles.homeCardsWrapper}>
              <div className={styles.homeCards}>
                <div className={`${styles.scrollWrapper} ${styles.leftContainer}`} />
                <Carousel setCurrentIndex={setCurrentIndex}>
                  {product.map((product, index) => (
                    <div className={styles.cards} key={index}>
                      <div className={styles.heroImageContainerVariantA}>
                        <Image src={product.image} width={190} height={130} alt={product.featuredTitle} />
                        <PropertyControlledComponent fallback={null} controllerProperty={product?.productDisclaimer}>
                          <p className={styles.disclaimer}>{product?.productDisclaimer}</p>
                        </PropertyControlledComponent>
                      </div>
                      <h2 className={styles.productHeading}>{product.featuredTitle}</h2>
                      <h3>{product.featuredSubtitle}</h3>
                    </div>
                  ))}
                </Carousel>
                <div className={`${styles.scrollWrapper} ${styles.rightContainer}`} />
              </div>

              <div className={styles.highlightSection}>
                {Array.from({ length: PRODUCTS_LENGTH }, (_, index) => (
                  <div className={`${styles.highlightedDot} ${index === currentIndex && styles.activeDot}`} />
                ))}
              </div>
            </div>
            <div className={styles.productCardWrappers}>
              {product
                .filter(product => product.featured)
                .map((productItem, index) => (
                  <ProductCardButton featured product={productItem} index={index} currentIndex={currentIndex} />
                ))}
            </div>
            <div className={styles.productCardWrappers}>
              {product
                .filter(product => !product.featured)
                .map((productItem, index) => (
                  <ProductCardButton
                    featured={false}
                    product={productItem}
                    index={FEATURED_CARD_OFFSET + index}
                    currentIndex={currentIndex}
                  />
                ))}
            </div>
          </When>
        </div>
        <When condition={CURRENT_VARIANT === VARIANT.WITHOUT_PRODUCT_CARDS}>
          <p className={styles.productHeading}>{productHeading}</p>
          <div className={styles.productsDesktop}>
            {product.map(product => (
              <p className={styles.product}>{product.shortName}</p>
            ))}
          </div>
          <div className={styles.ctaWeb}>
            <PrimaryButton
              link={HOME_PAGE_ROUTE}
              text={ctaText}
              segmentEventName="btn_explore_insurance"
              segmentEventProps={{ funnel: 'entry' }}
            />
          </div>
        </When>
        <div className={styles.dataWrapper} />
      </div>
      <When condition={CURRENT_VARIANT === VARIANT.WITHOUT_PRODUCT_CARDS}>
        <div className={styles.heroImageContainer}>
          <Image src={heroImage} alt="hero image" fill priority objectFit="contain" />
        </div>
      </When>
      <When condition={CURRENT_VARIANT === VARIANT.WITHOUT_PRODUCT_CARDS}>
        <div className={styles.productsMobile}>
          {product.map(product => (
            <p className={styles.product}>{product.shortName}</p>
          ))}
        </div>
        <div className={styles.ctaMobile}>
          <PrimaryButton
            link={HOME_PAGE_ROUTE}
            text={ctaTextMobile}
            segmentEventName="btn_explore_insurance"
            segmentEventProps={{ funnel: 'entry' }}
          />
        </div>
      </When>
    </div>
  )
}

export default HomeLandingWidget
